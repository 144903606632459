<template>
  <div v-if="!!perfumeCategoryFetched" role="main" class="product-line">
    <div class="back" @click="onBackToHome" v-html="$t('back_to_home')" />
    <div class="product-line_description">
      <div class="product-line_title">{{ perfumeCategoryFetched.name }}</div>
      <!-- <div class="product-line_subtitle" v-html="$t('eau_de_parfum')" /> -->
      <div class="product-line_text">
        {{ perfumeCategoryFetched.description }}
      </div>
      <div class="product-line_arrow-text" v-html="$t('click_or_discover')" />
      <div class="arrow" />
    </div>
    <transition name="fade">
      <horizontal-scroll
        v-if="
          categorizedPerfumesFetched && categorizedPerfumesFetched.length > 0
        "
        class="product-line_products"
      >
        <div
          v-for="product in categorizedPerfumesFetched"
          :key="product.id"
          class="product-line_product"
          v-on:click="handleProductClick(product.id)"
          :style="{
            backgroundColor: product.backgroundColor,
            color: pickTextColorBasedOnBgColorSimple(product.backgroundColor),
          }"
        >
          <!-- <img :src="product.imageUrl" :alt="product.title" /> -->
          <div
            class="product-line_product_image"
            :style="'background-image: url(' + product.imageUrl + ')'"
          ></div>
          <div class="product-line_product_body">
            <div class="product-line_product_title">{{ product.title }}</div>
            <div
              class="product-line_product_subtitle"
              v-html="$t('the_original_eau_de_parfum')"
            />
            <div class="product-line_bottom">
              <div class="product-intensities">
                <div class="product-intensity">
                  <intensities-table 
                    :color="pickTextColorBasedOnBgColorSimple(product.backgroundColor)" 
                    :perfume="product" 
                    :end="true"></intensities-table>
                  <!-- <div class="product-intensity_name">
                    {{ product.family.name }}
                  </div>
                  <IntensityDots
                    class="product-intensity_dots"
                    :intensity="product.family.intensity / 2"
                    :size="'s'"
                    :color="
                      pickTextColorBasedOnBgColorSimple(product.backgroundColor)
                    "
                  />
                </div>
                <div class="product-intensity" :key="product.family.name">
                  <div class="product-intensity_name">
                    {{ product.secondaryFamily.name }}
                  </div>
                  <IntensityDots
                    class="product-intensity_dots"
                    :intensity="product.secondaryFamily.intensity / 2"
                    :size="'s'"
                    :color="
                      pickTextColorBasedOnBgColorSimple(product.backgroundColor)
                    "
                  /> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </horizontal-scroll>
    </transition>
  </div>
</template>
<script>
import IntensitiesTable from "../components/IntensitiesTable.vue";
import queryStringMixin from "../mixins/queryStringMixin";
import { PERFUME, WIZARD_V2 } from "../router";
import HorizontalScroll from "vue-horizontal-scroll";
import "vue-horizontal-scroll/dist/vue-horizontal-scroll.css";
import getSalepointMixin from "../mixins/getSalepointMixin";
import { pickTextColorBasedOnBgColorSimple } from "../utils/colorUtils";
export default {
  components: {
    IntensitiesTable,
    HorizontalScroll,
  },
  mixins: [queryStringMixin, getSalepointMixin],
  methods: {
    onBackToHome() {
      this.$router.push({ name: WIZARD_V2 });
    },
    handleProductClick(perfumeId) {
      this.$router.push({
        name: PERFUME,
        params: {
          category: this.$route.params.category,
          perfume: perfumeId,
        },
        query: {
          gender: this.gender,
        },
      });
    },
    pickTextColorBasedOnBgColorSimple,
  },
  fetch: {
    perfumeCategoryFetched: {
      model: "perfumeCategory",
      method: "getById",
      params() {
        /** In this view catalogue always is a id */
        return [
          this.$route.params.category,
          this.$route.params.catalogue,
          this.gender,
        ];
      },
    },
    categorizedPerfumesFetched: {
      model: "categorizedPerfumes",
      method: "get",
      params() {
        return [
          this.$route.params.category,
          this.gender,
          this.$route.params
            .catalogue /** In this view catalogue always is a id */,
        ];
      },
    },
  },
  mounted() {
    this.eventTracker.emitEvent("VIEW_OPENED", {
      type: "COLLECTION",
      data: { id: parseInt(this.$route.params.category) },
    });
  },
  computed: {
    loading() {
      return this.isPerfumeCategoryFetchedUpdating;
    },
  },
};
</script>
<style lang="stylus" scoped>
.back
  m-font("Paco")
  m-font-size(12, 14)
  m-letter-spacing(60)
  cursor: pointer
  left: vw(50px)
  position: absolute
  text-transform: uppercase
  top: vh(26px)
  z-index: 1

  &:after
    background-color: #000
    content: ""
    display: block
    height: 1px
    margin-top: vw(7px)
    width: 100%

.product-line
  display: flex
  height: 100%

.product-line_description
  display: flex
  flex: 0 0 vw(421px)
  flex-direction: column
  height: 100vh
  padding-bottom: vh(100px)
  padding-left: vw(76px)
  padding-right: vw(38px)
  padding-top: vh(128px)

.product-line_title
  m-font("DIN Engschrift Std")
  m-font-size(65, 65)
  margin-bottom: vh(20px)
  text-transform: uppercase

.product-line_subtitle
  m-font-size(15, 25)
  margin-bottom: vh(30px)

.product-line_text
  m-font-size(16, 26)
  flex: 1 1 0%
  margin-bottom: vh(48px)
  overflow-y: auto
  text-align: justify

.product-line_arrow-text
  m-font("DIN Engschrift Std")
  m-font-size(45, 45)
  margin-bottom: 4vh
  text-transform: uppercase

.arrow
  animation: arrow
  animation-duration: 4s
  animation-iteration-count: infinite
  background-color: $mine-shaft
  height: 2px
  position: relative
  width: 100%

  &:before,
  &:after
    background: $mine-shaft
    content: ""
    height: 2px
    position: absolute
    width: 12px

  &:before
    bottom: -4px
    right: -4px
    transform: rotate(-45deg)

  &:after
    right: -4px
    top: -4px
    transform: rotate(45deg)

  @keyframes arrow
    0%
      width: 10%

    50%
      width: 100%

    100%
      width: 10%

.product-line_products
  display: flex
  flex: 1 1 0%
  height: 100vh
  padding-bottom: vw(90px)
  padding-left: vw(35px)
  padding-top: vw(90px)

  &._horizontal-scroll
    overflow-x: auto

  &.fade-enter-active,
  &.fade-leave-active
    transition: all 0.5s

  &.fade-enter
    opacity: 0

.product-line_product
  display: flex
  flex: 0 0 percentage((1 / 3))
  flex-direction: column
  margin-right: vw(70px)
  width: percentage((1 / 3))

  @media (max-width: 1500px)
    flex: 0 0 percentage((1 / 2.25))
    width: percentage((1 / 2.25))

  @media (max-width: 1200px)
    flex: 0 0 percentage((1 / 2))
    width: percentage((1 / 2))

  @media (max-width: 900px)
    flex: 0 0 percentage((1 / 1.5))
    width: percentage((1 / 1.5))

  @media (max-width: 700px)
    flex: 0 0 percentage((1 / 1.05))
    width: percentage((1 / 1.05))

.product-line_product_image
  background-position: center center
  background-repeat: no-repeat
  background-size: contain
  flex: 0 0 50%
  height: 50%
  margin-top: vh(40px)
  width: 100%

.product-line_product_body
  display: flex
  flex-direction: column
  height: vw(400px)
  padding: vw(70px)

.product-line_product_title
  m-font("DIN Engschrift Std")
  m-font-size(30, 30)
  margin-bottom: vh(15px)
  text-align: center
  text-transform: uppercase

.product-line_bottom
  display: flex
  flex: 1 1 0%
  flex-direction: column
  justify-content: flex-end

.product-line_product_subtitle
  m-font-size(16, 20)
  text-align: center

.product-intensities
  overflow: hidden

.product-intensity
  m-font-size(14, 18)
  align-items: center
  display: flex
  margin-bottom: vw(15px)
  overflow: hidden
  width: 100%
  >>>.intensities
    margin auto
    width:100%

.product-intensity_name
  flex: 1 1 0%
  margin-right: vw(15px)
  white-space: initial
</style>